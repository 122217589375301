import {Button as MuiButton} from "@mui/material";
import MuiLoadingButton from "@mui/lab/LoadingButton";
import {cx} from "@emotion/css";
import {useMemo} from "react";

import {IButton} from "../IButton";
import {styles} from "./TertiaryButton.styles";
import {commonStyles} from "../_common/styles";

export interface BmTertiaryButtonProps
  extends Pick<IButton, "className" | "sx" | "type" | "disabled" | "size" | "onClick" | "children"> {
  loading?: boolean;
  loadingIndicator?: string;
}

export const BmTertiaryButton: React.FC<BmTertiaryButtonProps> = ({
  className,
  loading,
  loadingIndicator,
  size = "large",
  ...restProps
}) => {
  const commonProps = useMemo(
    () => ({
      className: cx(commonStyles.button, styles.tertiaryButton, className),
      size,
      ...restProps,
    }),
    [className, size, restProps],
  );

  return loading === undefined ? (
    <MuiButton variant="contained" {...commonProps} />
  ) : (
    <MuiLoadingButton variant="contained" loading={loading} loadingIndicator={loadingIndicator} {...commonProps} />
  );
};
