import React, {useCallback} from "react";
import MuiCheckbox from "@mui/material/Checkbox";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import {useController} from "react-hook-form";
import {cx} from "@emotion/css";
import get from "lodash.get";
import {FormControl} from "@mui/material";

import {BmCheckboxCheckedIcon, BmCheckboxEmptyIcon, BmCheckboxIndeterminateIcon} from "~/components/icons/simpleIcons";
import {BmHelperText} from "~/components/helperText";

import {ControlledInputProps} from "../_common/types";
import {styles} from "./Checkbox.styles";

export interface BmCheckboxProps {
  name?: string;
  checked: boolean;
  error?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string | React.ReactElement;
  helperText?: string;
  indeterminate?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const BmCheckbox = React.forwardRef<HTMLInputElement, BmCheckboxProps>(function BmCheckbox(
  {name, checked, error, className, disabled, label, helperText, indeterminate, onChange},
  ref,
) {
  return (
    <FormControl error={error} component="fieldset" variant="standard">
      <MuiFormControlLabel
        name={name}
        checked={checked}
        disabled={disabled}
        label={label}
        className={cx(styles.label, className)}
        control={
          <MuiCheckbox
            icon={<BmCheckboxEmptyIcon id="checkbox-empty" />}
            checkedIcon={<BmCheckboxCheckedIcon id="checkbox-checked" />}
            onChange={onChange}
            inputRef={ref}
            indeterminate={indeterminate}
            indeterminateIcon={<BmCheckboxIndeterminateIcon id="checkbox-indeterminate" />}
            className={cx(styles.checkbox, error && styles.error)}
          />
        }
      />
      {helperText && <BmHelperText error={error}>{helperText}</BmHelperText>}
    </FormControl>
  );
});

interface BmControlledCheckboxProps
  extends ControlledInputProps,
    Omit<BmCheckboxProps, "onChange" | "name" | "checked" | "error"> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const BmControlledCheckbox: React.FC<BmControlledCheckboxProps> = ({
  name,
  control,
  rules,
  helperText,
  defaultValue = false,
  onChange,
  ...restProps
}) => {
  const {
    field: {value, ...field},
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange = useCallback(
    (event, value) => {
      onChange?.(event, value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmCheckbox
      {...field}
      {...restProps}
      checked={value}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
