import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

import main from "./translations/en/main.json";
import termsConditions from "./translations/en/termsConditions.json";
import brandLanguages from "./translations/en/brandLanguages.json";
import errors from "./translations/en/errors.json";
import labels from "./translations/en/labels.json";
import buttons from "./translations/en/buttons.json";
import toasts from "./translations/en/toasts.json";
import breadcrumbs from "./translations/en/breadcrumbs.json";
import tooltips from "./translations/en/tooltips.json";

export const resources = {
  en: {
    translations: {
      ...main.translations,
      ...termsConditions.translations,
      ...brandLanguages.translations,
      ...errors.translations,
      ...labels.translations,
      ...buttons.translations,
      ...toasts.translations,
      ...breadcrumbs.translations,
      ...tooltips.translations,
    },
  },
} as const;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    lng: "en",
    fallbackLng: "en",
    defaultNS: "translations",
  });
export default i18n;
