import React, {useCallback, useState} from "react";
import {useController} from "react-hook-form";
import {
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  FormControlLabel as MuiFormControlLabel,
  FormControl,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import {cx} from "@emotion/css";
import get from "lodash.get";

import {BmHelperText} from "~/components/helperText";
import {BmRadioCheckedIcon, BmRadioEmptyIcon} from "~/components/icons/simpleIcons";
import {noop} from "~/utils/noop";

import {ControlledInputProps} from "../_common/types";
import {RadioType} from "./IRadioGroup";
import {styles} from "./RadioGroup.styles";

export interface BmRadioGroupCollapseProps {
  id?: string;
  name: string;
  className?: string;
  options: RadioType[];
  ariaLabel?: string;
  value?: string | null;
  disabled?: boolean;
  horizontal?: boolean;
  fitContent?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const BmRadioGroupCollapse = React.forwardRef<HTMLInputElement, BmRadioGroupCollapseProps>(
  function BmRadioGroupCollapse(
    {
      id,
      name,
      className,
      options: radioButtons,
      ariaLabel,
      value,
      disabled,
      horizontal = false,
      fitContent = true,
      error,
      helperText,
      onChange,
    },
    ref,
  ) {
    const [selectedRadio, setSelectedRadio] = useState<string | null | undefined>(value);
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSelectedRadio(value);
      onChange(event, value);
    };
    return (
      <FormControl id={id} className={cx(styles.root(fitContent))} error={error} variant="standard">
        <MuiRadioGroup
          ref={ref}
          aria-label={ariaLabel}
          name={name}
          className={className}
          onChange={disabled ? noop : handleRadioChange}
          value={selectedRadio ?? null}
          row={horizontal}
          sx={{gap: 2}}
        >
          {radioButtons.map((radioButton: RadioType) => {
            const isExpanded = selectedRadio === radioButton.value && !!radioButton.underLabel;
            return (
              <Accordion
                key={radioButton.value}
                expanded={isExpanded}
                onChange={() => setSelectedRadio(selectedRadio === radioButton.value ? null : radioButton.value)}
                className={styles.accordion(!!error)}
              >
                <AccordionSummary aria-controls={`${radioButton.value}-content`} id={`${radioButton.value}-header`}>
                  <MuiFormControlLabel
                    value={radioButton.value}
                    className={styles.label}
                    control={
                      <MuiRadio
                        icon={<BmRadioEmptyIcon id="radio-empty" />}
                        checkedIcon={<BmRadioCheckedIcon id="radio-checked" />}
                        disabled={disabled || radioButton.disabled}
                        className={cx(styles.radio, !!error && styles.error)}
                      />
                    }
                    label={radioButton.label}
                    disabled={disabled || radioButton.disabled}
                    onClick={(e) => e.stopPropagation()}
                    onFocus={(e) => e.stopPropagation()}
                  />
                  {radioButton.helperText && <BmHelperText sx={{pl: 5, mb: 1}}>{radioButton.helperText}</BmHelperText>}
                  {radioButton.labelDescription}
                </AccordionSummary>
                {isExpanded && <AccordionDetails>{radioButton.underLabel}</AccordionDetails>}
              </Accordion>
            );
          })}
        </MuiRadioGroup>
        {helperText && <BmHelperText error={error}>{helperText}</BmHelperText>}
      </FormControl>
    );
  },
);

export interface BmControlledRadioGroupCollapseProps
  extends ControlledInputProps,
    Omit<BmRadioGroupCollapseProps, "onChange" | "value" | "error"> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}
export const BmControlledRadioGroupCollapse: React.FC<BmControlledRadioGroupCollapseProps> = ({
  name,
  defaultValue,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
      onChange?.(e, value);
      field.onChange(e);
    },
    [field, onChange],
  );
  return (
    <BmRadioGroupCollapse
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
