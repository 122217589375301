// If we want to use a feature flag in the frontend, add it here with the value matching
// the key sent form the backend: https://github.com/bemakers/app/blob/main/data/bemakers.rb#L116
// Some feature flags can be backend only, so they don't need to be defined here
export const MakerFeatures = {
  Storefront: "storefront",
  OwnDelivery: "own_delivery",
  Brandstore: "brandstore",
  ChargeMethod: "charge_method",
  GlobalB2BExtra: "global_b2b_extra",
};
