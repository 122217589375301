import {css} from "@emotion/css";

import {designColors, designConstants, designUtils} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  tableRow: (newMobileDesign: boolean, draggable: boolean, active: boolean) => css`
    &.MuiTableRow-root {
      border-top: 1px solid ${designColors.grey_3};
      border-bottom: 1px solid ${designColors.grey_3};

      &:nth-child(2n) {
        background: ${designColors.white};
      }
      &:nth-child(2n + 1) {
        background: ${designColors.off_white};
      }

      ${!draggable &&
      css`
        transition: ${designUtils.transition(0.3)};
      `}

      ${active &&
      css`
        td {
          background-color: ${designColors.blue_1};
        }
      `}

      ${theme.breakpoints.down("md")} {
        transition: none;

        td {
          &:first-child {
            padding-top: 24px;
          }
          &:last-child {
            padding-bottom: 24px;
          }
        }

        ${newMobileDesign &&
        css`
          display: block;

          border: none;
          border-radius: ${designConstants.borderRadius};
          background-color: ${designColors.white} !important;
          box-shadow: ${designConstants.boxShadow};
        `}
      }
    }
    &.MuiTableRow-root.MuiTableRow-hover {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      ${!newMobileDesign &&
      css`
        &:hover {
          ${theme.breakpoints.down("md")} {
            background-color: transparent;
            td {
              color: inherit;
            }
          }
        }
      `}
    }
  `,
};
