import {createContext, useContext} from "react";

interface ITableConfig {
  newMobileDesign: boolean;
}

const BmTableConfigContext = createContext<ITableConfig>({
  newMobileDesign: false,
});

export const BmTableConfigContextProvider = BmTableConfigContext.Provider;

export const useBmTableConfigContext = () => useContext(BmTableConfigContext);
