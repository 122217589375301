import React, {ChangeEventHandler, HTMLAttributes, useCallback, useState} from "react";
import {useController} from "react-hook-form";
import get from "lodash.get";
import {cx} from "@emotion/css";
import {TextField, InputAdornment} from "@mui/material";

import {CommonInputType, ControlledInputProps} from "../_common/types";
import {commonStyles} from "../_common/styles";
import {BmInputHelperText} from "../_common/InputHelperText";

export interface BmTextInputProps extends CommonInputType {
  className?: string;
  value?: string | null | undefined;
  inputMode?: HTMLAttributes<any>["inputMode"];
  focusOnMount?: boolean;
  startAdornment?: string;
  charLimit?: number;

  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const BmTextInput = React.forwardRef<HTMLInputElement, BmTextInputProps>(function BmTextInput(
  {
    className,
    inputMode = "text",
    startAdornment,
    endAdornment,
    focusOnMount,
    value,
    helperText,
    charLimit,
    ...restProps
  },
  ref,
) {
  const [isAutoFill, setIsAutoFill] = useState(false);

  return (
    <TextField
      {...restProps}
      InputProps={{
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
        onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
          e.animationName === "mui-auto-fill" && setIsAutoFill(true);
        },
        onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
          e.animationName === "mui-auto-fill-cancel" && setIsAutoFill(false),
        onFocus: () => setIsAutoFill(false),
      }}
      inputProps={{inputMode, ref, maxLength: charLimit}}
      InputLabelProps={{shrink: isAutoFill || undefined}}
      className={cx(commonStyles.input(!!endAdornment), className)}
      variant="outlined"
      fullWidth={true}
      autoFocus={focusOnMount}
      // because MuiTextField forbid Nil value
      value={value || ""}
      helperText={
        (helperText || charLimit) && (
          <BmInputHelperText helperText={helperText} charLimit={charLimit} value={value || ""} />
        )
      }
    />
  );
});

export interface BmControlledTextInputProps
  extends ControlledInputProps,
    Omit<BmTextInputProps, "onChange" | "value" | "error"> {
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const BmControlledTextInput: React.FC<BmControlledTextInputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmTextInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
