import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgCheckInCloud} from "~/assets/svg/check-in-cloud.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmCheckInCloudIcon: React.FC<IIconBase> = ({id, size = "xs", color = designColors.black, className}) => {
  return <SvgCheckInCloud id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
