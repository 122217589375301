import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgBoxCheckIcon} from "~/assets/svg/box-check.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmBoxCheckIcon: React.FC<IIconBase> = ({id, size = "sm", color = designColors.black, className}) => {
  return <SvgBoxCheckIcon id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
