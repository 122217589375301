import {css} from "@emotion/css";

import {designColors, designConstants, designTokens} from "~/ui";

export const styles = {
  root: (fitContent: boolean) =>
    fitContent
      ? css`
          display: flex;
          max-width: fit-content;
        `
      : css`
          width: 100%;
        `,
  label: css`
    &.MuiFormControlLabel-root {
      position: relative;
      min-height: 24px;

      margin-left: -6px;
    }
    > .MuiTypography-root {
      padding-left: 35px;
    }
  `,
  radio: css`
    &.MuiRadio-root {
      position: absolute;
      padding: 6px;
    }
    &.Mui-disabled svg {
      *[stroke^="\#"] {
        stroke: rgba(0, 0, 0, 0.26);
      }
    }
    &.Mui-disabled.Mui-checked svg {
      *[stroke^="\#"] {
        stroke: rgba(0, 0, 0, 0.26);
      }
      *[fill^="\#"] {
        fill: rgba(0, 0, 0, 0.26);
      }
    }
  `,
  error: css`
    &.MuiRadio-root svg {
      *[stroke^="\#"] {
        stroke: ${designColors.error};
      }
    }
    &.Mui-checked svg {
      *[stroke^="\#"] {
        stroke: ${designColors.error};
      }
      *[fill^="\#"] {
        fill: ${designColors.error};
      }
    }
  `,
  outlinedOption: css`
    width: 100%;
    border: 1px solid ${designColors.grey_2};
    border-radius: ${designConstants.borderRadius};
    padding: 9px 18px 18px;
    margin-bottom: 12px;
    .MuiFormControlLabel-root {
      width: 100%;
    }
  `,
  outlinedOptionActive: css`
    border: 3px solid ${designColors.primary};
    .MuiTypography-root {
      font-weight: ${designTokens.fontWeight.bold};
    }

    fieldset .MuiTypography-root {
      font-weight: ${designTokens.fontWeight.regular};
    }
  `,
  accordion: (error: boolean) => css`
    &.MuiPaper-root {
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;

      &:before {
        display: none;
      }

      &.Mui-expanded {
        margin: 0;
      }
    }
    .MuiAccordionSummary-root {
      min-height: auto;
      padding: 0;

      &.Mui-expanded {
        min-height: auto;
      }
    }
    .MuiAccordionSummary-content {
      flex-direction: column;
      margin: 0;

      &.Mui-expanded {
        margin: 0;
      }
    }
    .MuiCollapse-wrapper {
      padding-left: 10px;
      padding-bottom: 12px;
    }
    .MuiAccordionDetails-root {
      padding: 12px;
      ${error
        ? css`
            border-left: 2px solid ${designColors.error};
          `
        : css`
            border-left: 2px solid ${designColors.blue_2};
          `}
    }
  `,
};
