import {useEffect, useMemo} from "react";

import {
  MembershipRole,
  useLazyGetCompanyInfoQuery,
  useLazyGetVitalCompanyInfoQuery,
  VitalCompanySummaryFragment,
} from "~/api/generated";
import {useAppSelector, strongSelectMaker, strongSelectUserRole} from "~/modules/redux";
import {enhanceCompanyData} from "~/utils/company";

export const useCompanyInfoQuery = (): {data: VitalCompanySummaryFragment | null; isLoading: boolean} => {
  const maker = useAppSelector(strongSelectMaker);
  const userRole = useAppSelector(strongSelectUserRole);

  const [getCompanyInfo, {data: companyInfoData, isLoading: isCompanyInfoDataLoading}] = useLazyGetCompanyInfoQuery();
  const [getVitalCompanyInfo, {data: vitalCompanyInfoData, isLoading: isVitalCompanyInfoDataLoading}] =
    useLazyGetVitalCompanyInfoQuery();

  const companyData = useMemo(() => {
    switch (userRole) {
      case MembershipRole.Guest:
        return vitalCompanyInfoData?.maker ? enhanceCompanyData(vitalCompanyInfoData.maker) : null;

      case MembershipRole.Sales:
      case MembershipRole.Owner:
        return companyInfoData?.maker ? enhanceCompanyData(companyInfoData.maker) : null;
      default:
        return null;
    }
  }, [userRole, companyInfoData?.maker, vitalCompanyInfoData?.maker]);

  const isLoading = useMemo(() => {
    switch (userRole) {
      case MembershipRole.Guest:
        return isVitalCompanyInfoDataLoading;

      case MembershipRole.Sales:
      case MembershipRole.Owner:
        return isCompanyInfoDataLoading;
      default:
        return false;
    }
  }, [isCompanyInfoDataLoading, isVitalCompanyInfoDataLoading, userRole]);

  useEffect(() => {
    switch (userRole) {
      case MembershipRole.Guest:
        getVitalCompanyInfo({makerId: maker.id});
        break;
      case MembershipRole.Sales:
      case MembershipRole.Owner:
        getCompanyInfo({makerId: maker.id});
        break;
      default:
        break;
    }
  }, [getCompanyInfo, getVitalCompanyInfo, maker.id, userRole]);

  return {data: companyData, isLoading};
};
