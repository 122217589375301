import React, {useCallback, useMemo, useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useController} from "react-hook-form";
import get from "lodash.get";
import {cx} from "@emotion/css";
import {Box} from "@mui/material";

import {BmWhiteButton} from "~/components/buttons";
import {BmAlertIcon, BmCheckInCloudIcon, BmHelpCircleIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";
import {BmTooltip} from "~/components/tooltip";

import {BmTextInput, BmTextInputProps} from "../../TextInput";
import {styles} from "./VATValidationInput.styles";
import {ControlledInputProps} from "../../../_common/types";

export enum VATValidationInputStateEnum {
  empty = "empty",
  filled = "filled",
  validating = "validating",
  valid = "valid",
  invalid = "invalid",
  noMatch = "noMatch",
}

export type VATValidationResultType = "valid" | "invalid" | "noMatch";

const getInitialInputState = (
  initialInputState?: VATValidationInputStateEnum,
  value?: string | null,
): VATValidationInputStateEnum => {
  if (initialInputState) return initialInputState;
  return value ? VATValidationInputStateEnum.filled : VATValidationInputStateEnum.empty;
};

export interface VATValidationInputProps extends Omit<BmTextInputProps, "onChange"> {
  isValidating?: boolean;
  validationResult?: VATValidationResultType;
  initialInputState?: VATValidationInputStateEnum;
  onChange: (value: string) => void;
  onValiadteClick: (VATNumber: string) => void;
  onInputStateChange?: (state: VATValidationInputStateEnum) => void;
}

export const VATValidationInput = React.forwardRef<HTMLInputElement, VATValidationInputProps>(
  function VATValidationInput(
    {
      className,
      value,
      isValidating,
      validationResult,
      initialInputState,
      onChange,
      onValiadteClick,
      onInputStateChange,
      ...restProps
    },
    ref,
  ) {
    const {t} = useTranslation();
    const [inputState, goToState] = useState<VATValidationInputStateEnum>(
      getInitialInputState(initialInputState, value),
    );

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const nextValue = e.target.value;
        onChange(nextValue);

        goToState(nextValue ? VATValidationInputStateEnum.filled : VATValidationInputStateEnum.empty);
      },
      [onChange],
    );

    const handleValidateClick = useCallback(() => {
      goToState(VATValidationInputStateEnum.validating);
      onValiadteClick(value!);
    }, [onValiadteClick, value]);

    useEffect(() => {
      if (inputState !== VATValidationInputStateEnum.filled) return;
      if (!isValidating) return;
      goToState(VATValidationInputStateEnum.validating);
    }, [inputState, isValidating]);

    useEffect(() => {
      if (inputState !== VATValidationInputStateEnum.validating) return;
      if (isValidating) return;
      switch (validationResult) {
        case "valid":
          goToState(VATValidationInputStateEnum.valid);
          break;
        case "invalid":
          goToState(VATValidationInputStateEnum.invalid);
          break;
        case "noMatch":
          goToState(VATValidationInputStateEnum.noMatch);
          break;
        default:
          break;
      }
    }, [inputState, isValidating, validationResult]);

    useEffect(() => {
      onInputStateChange?.(inputState);
    }, [inputState, onInputStateChange]);

    const stateBasedProps = useMemo(() => {
      switch (inputState) {
        case VATValidationInputStateEnum.filled:
        case VATValidationInputStateEnum.validating:
          return {
            endAdornment: (
              <BmWhiteButton size="small" onClick={handleValidateClick} loading={isValidating} sx={{mr: -1}}>
                {t("buttons.validate")}
              </BmWhiteButton>
            ),
          };
        case VATValidationInputStateEnum.valid:
          return {
            endAdornment: (
              <BmTooltip title={t("tooltips.validated")}>
                <Box>
                  <BmCheckInCloudIcon id="check-in-cloud-icon" size="sm" color={designColors.green_2} />
                </Box>
              </BmTooltip>
            ),
          };
        case VATValidationInputStateEnum.noMatch:
          return {
            endAdornment: (
              <BmTooltip title={t("tooltips.no_match_vat")}>
                <Box>
                  <BmHelpCircleIcon id="help-circle-icon" size="sm" />
                </Box>
              </BmTooltip>
            ),
          };
        case VATValidationInputStateEnum.invalid:
          return {
            endAdornment: (
              <BmTooltip title={t("tooltips.not_valid_vat")}>
                <Box>
                  <BmAlertIcon id="alert-icon" size="sm" color={designColors.error} />
                </Box>
              </BmTooltip>
            ),
          };
        case VATValidationInputStateEnum.empty:
        default:
          return {};
      }
    }, [handleValidateClick, inputState, isValidating, t]);

    return (
      <BmTextInput
        ref={ref}
        className={cx(className, styles.root(inputState))}
        value={value}
        onChange={handleOnChange}
        {...stateBasedProps}
        {...restProps}
      />
    );
  },
);

export interface ControlledVATValidationInputProps
  extends ControlledInputProps,
    Omit<VATValidationInputProps, "onChange" | "value" | "error"> {
  onChange?: (value: string) => void;
}

export const ControlledVATValidationInput: React.FC<ControlledVATValidationInputProps> = ({
  name,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: string) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <VATValidationInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
