import {AuthRight, MembershipRole} from "~/api/generated";
import {areUnorderedArraysEqual} from "~/utils/arrays";

const defaultRoleRights = {
  guest: ["COMMON_READ", "BUSINESS_TOOLS"],
  sales: [
    "COMMON_READ",
    "ORDER_READ",
    "ORDER_WRITE",
    "PRODUCT_READ",
    "CUSTOMER_READ",
    "CUSTOMER_WRITE",
    "PRICING_GROUP_READ",
  ],
};

export const guessRoleBasedOnRights = (rights: AuthRight[]): MembershipRole => {
  switch (true) {
    case areUnorderedArraysEqual(rights, defaultRoleRights.guest):
      return MembershipRole.Guest;
    case areUnorderedArraysEqual(rights, defaultRoleRights.sales):
      return MembershipRole.Sales;
    default:
      return MembershipRole.Owner;
  }
};
