import {designColors} from "~/ui";

import {BannerColorPresetType, IBannerColors} from "./types";

export const getColorsByPreset = (preset: BannerColorPresetType): IBannerColors => {
  switch (preset) {
    case "blue":
      return {
        backgroundColor: designColors.blue_1,
        borderColor: designColors.blue_3,
        titleColor: designColors.colorInk,
      };
    case "navy":
      return {
        backgroundColor: designColors.blue_1,
        borderColor: designColors.blue_3,
        titleColor: designColors.colorInk,
      };
    case "red":
      return {
        backgroundColor: designColors.red_1,
        borderColor: designColors.red_3,
        titleColor: designColors.colorInk,
      };
    case "yellow":
      return {
        borderColor: designColors.yellow_3,
        backgroundColor: designColors.yellow_1,
        titleColor: designColors.colorInk,
      };
    case "green":
      return {
        borderColor: designColors.green_3,
        backgroundColor: designColors.green_1,
        titleColor: designColors.colorInk,
      };
    case "lightGrey":
      return {
        backgroundColor: designColors.grey_1,
        borderColor: designColors.off_white,
        titleColor: designColors.colorInk,
      };
    case "grey":
    default:
      return {
        backgroundColor: designColors.white,
        borderColor: designColors.grey_3,
        titleColor: designColors.colorInk,
      };
  }
};
