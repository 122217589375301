import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useFeature} from "flagged";
import MenuList from "@mui/material/MenuList";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {matchPath, useLocation} from "react-router-dom";
import {useAbility} from "@casl/react";

import {
  BANNERS_PATH,
  BANNER_ROOT_PATH,
  COMPANY_INFO_ROOT_PATH,
  CUSTOMERS_PATH,
  LOCATIONS_ROOT_PATH,
  ORDERS_PATH,
  PAYMENT_INFO_ROOT_PATH,
  WEBSHOP_PRODUCTS_PATH,
  WEBSHOP_PRODUCTS_ROOT_PATH,
  DISCOUNT_ROOT_PATH,
  COLLECTION_ROOT_PATH,
  DISCOUNTS_PATH,
  COLLECTIONS_PATH,
  SHIPPING_RATES_ROOT_PATH,
  SHIPPING_RATES_PREVIEW_PATH,
  STOCK_MOVEMENTS_ROOT_PATH,
  CONFIGURATION_ROOT_PATH,
  USERS_PATH,
  BALANCE_PATH,
  WAREHOUSES_ROOT_PATH,
  LISTINGS_PATH,
  COMMERCE_RATES_ROOT_PATH,
  FULFILLMENT_RULES_ROOT_PATH,
  BRANDS_PATH,
  NOTES_FOR_SETTLEMENT_PATH,
  FINANCIAL_STATEMENTS_PATH,
  ORDER_TABS_PATH,
  BUSINESS_TOOLS_PATH,
  PRODUCTS_PATH,
  PRICE_GROUPS_PATH,
} from "~/modules/routing";
import {useAppSelector, strongSelectMaker} from "~/modules/redux";
import {
  BmFileIcon,
  BmProductsIcon,
  BmSettingsIcon,
  BmShippingIcon,
  BmShoppingIcon,
  BmUsersIcon,
  BmEnergyIcon,
  BmWarehouseIcon,
  BmBookOpenIcon,
  BmGlobeIcon,
} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";
import {VerticalAlignBetween} from "~/layouts/flexLayout";
import {MakerFeatures} from "~/features";
import {WATS_NEW_URL} from "~/constants/constants";
import {
  WarehouseKind,
  useLazyAllShopsQuery,
  useLazyGetAllWarehousesQuery,
  useLazyGetOrderTabsMetadataQuery,
} from "~/api/generated";
import {Can, AbilityContext} from "~/modules/roles";
import {useCompanyInfoContext} from "~/modules/company";

import {SidebarItem} from "../SidebarItem/SidebarItem";
import {SidebarSubItem} from "../SidebarSubItem/SidebarSubItem";
import {SidebarSubList} from "../SidebarSubList/SidebarSubList";
import {SidebarLink} from "../SidebarLink/SidebarLink";
import {SidebarSection} from "../SidebarSection/SidebarSection";
import {SidebarItemContent} from "../SidebarItemContent/SidebarItemContent";
import {SidebarUser} from "../SidebarUser/SidebarUser";
import {SidebarItemBadge} from "../SidebarItemBadge/SidebarItemBadge";

export const SidebarList: React.FC = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const abilities = useAbility(AbilityContext);

  const maker = useAppSelector(strongSelectMaker);
  const companyInfo = useCompanyInfoContext();
  const storefront = useFeature(MakerFeatures.Storefront);

  const [getAllShops, {data: allShopsData}] = useLazyAllShopsQuery();
  const [getAllWarehouses, {data: allWarehousesData}] = useLazyGetAllWarehousesQuery();
  const [getOrderTabsMetadata, {data: orderTabsMetadata}] = useLazyGetOrderTabsMetadataQuery();

  const isNotMaker = useMemo(() => maker === null, [maker]);
  const isAnyWarehouse = useMemo(() => !!allWarehousesData?.warehouses.length, [allWarehousesData?.warehouses]);
  const isOnlyExternalWarehouse = useMemo(
    () => allWarehousesData?.warehouses.every((warehouse) => warehouse.kind === WarehouseKind.External),
    [allWarehousesData?.warehouses],
  );
  const isClickAndCollect = useMemo(() => {
    const externalWarehouse = allWarehousesData?.warehouses.find(({kind}) => kind === WarehouseKind.External);

    if (!externalWarehouse) return false;
    if (externalWarehouse.country !== companyInfo?.country) return false;
    return true;
  }, [allWarehousesData?.warehouses, companyInfo?.country]);

  useEffect(() => {
    abilities.can("read", "commerce") && getAllShops({makerId: maker.id});
    abilities.can("read", "fulfillment") && getAllWarehouses({makerId: maker.id});
    abilities.can("read", "order") && getOrderTabsMetadata({makerId: maker.id});
  }, [abilities, maker.id, getAllShops, getAllWarehouses, getOrderTabsMetadata]);

  return (
    <Box component={VerticalAlignBetween} minHeight="auto" height="100%">
      <MenuList sx={{p: 0}}>
        <Stack spacing={4} useFlexGap>
          <SidebarSection>
            <Can I="read" a="order">
              <SidebarItem
                disabled={isNotMaker}
                selected={[ORDERS_PATH, ORDER_TABS_PATH].some((pattern) =>
                  matchPath(location.pathname, {path: pattern, exact: true}),
                )}
              >
                <SidebarLink to={ORDERS_PATH} tooltipTitle={t("sidebar.tooltip")} disabled={isNotMaker}>
                  <SidebarItemContent
                    text={t("sidebar.orders")}
                    icon={<BmShippingIcon id="orders-icon" color={designColors.white} size="xs" />}
                    badge={
                      !!orderTabsMetadata?.orderTabsMetadata.newTabCount && (
                        <SidebarItemBadge>
                          {t("sidebar.amount_new", {amount: orderTabsMetadata.orderTabsMetadata.newTabCount})}
                        </SidebarItemBadge>
                      )
                    }
                  />
                </SidebarLink>
              </SidebarItem>
            </Can>

            <Can I="read" a="products">
              <SidebarItem disabled={isNotMaker}>
                <SidebarLink to={PRODUCTS_PATH} tooltipTitle={t("sidebar.tooltip")} disabled={isNotMaker}>
                  <SidebarItemContent
                    text={t("sidebar.products")}
                    icon={<BmProductsIcon id="products-icon" color={designColors.white} size="xs" />}
                  />
                </SidebarLink>
              </SidebarItem>
            </Can>

            <Can I="read" a="customers">
              <SidebarSection>
                <SidebarSubList
                  id="customers"
                  title={t("sidebar.group.customers")}
                  icon={<BmUsersIcon id="customers-icon" color={designColors.white} size="xs" />}
                  path={[CUSTOMERS_PATH, PRICE_GROUPS_PATH]}
                  tooltipTitle={t("sidebar.tooltip")}
                  disabled={isNotMaker}
                >
                  <SidebarSubItem
                    title={t("sidebar.customers")}
                    to={CUSTOMERS_PATH}
                    path={CUSTOMERS_PATH}
                    disabled={isNotMaker}
                  />

                  <SidebarSubItem
                    title={t("sidebar.price_groups")}
                    to={PRICE_GROUPS_PATH}
                    path={PRICE_GROUPS_PATH}
                    disabled={isNotMaker}
                  />
                </SidebarSubList>
              </SidebarSection>
            </Can>

            <Can I="read" a="report">
              <SidebarItem
                disabled={isNotMaker}
                selected={[BALANCE_PATH, NOTES_FOR_SETTLEMENT_PATH, FINANCIAL_STATEMENTS_PATH].includes(
                  location.pathname,
                )}
              >
                <SidebarLink to={BALANCE_PATH} tooltipTitle={t("sidebar.tooltip")} disabled={isNotMaker}>
                  <SidebarItemContent
                    text={t("sidebar.reports")}
                    icon={<BmFileIcon id="file-icon" color={designColors.white} size="xs" />}
                  />
                </SidebarLink>
              </SidebarItem>
            </Can>
          </SidebarSection>

          <Can I="read" a="commerce">
            <SidebarSection>
              {allShopsData?.shops?.length ? (
                <>
                  <SidebarSubList
                    id="market"
                    title={t("sidebar.group.commerce")}
                    icon={<BmShoppingIcon id="market-icon" color={designColors.white} size="xs" />}
                    path={[
                      WEBSHOP_PRODUCTS_PATH,
                      DISCOUNTS_PATH,
                      COLLECTIONS_PATH,
                      BANNERS_PATH,
                      SHIPPING_RATES_ROOT_PATH,
                    ]}
                    tooltipTitle={t("sidebar.tooltip")}
                    disabled={isNotMaker}
                  >
                    <SidebarSubItem
                      title={t("sidebar.webshop_products")}
                      to={WEBSHOP_PRODUCTS_ROOT_PATH}
                      path={WEBSHOP_PRODUCTS_PATH}
                      disabled={isNotMaker}
                    />

                    {storefront && (
                      <>
                        <SidebarSubItem
                          title={t("sidebar.collections")}
                          to={COLLECTION_ROOT_PATH}
                          path={COLLECTIONS_PATH}
                          disabled={isNotMaker}
                        />
                        <SidebarSubItem
                          title={t("sidebar.discounts")}
                          to={DISCOUNT_ROOT_PATH}
                          path={DISCOUNTS_PATH}
                          disabled={isNotMaker}
                        />
                        <SidebarSubItem
                          title={t("sidebar.banners")}
                          to={BANNER_ROOT_PATH}
                          path={BANNERS_PATH}
                          disabled={isNotMaker}
                        />
                        <SidebarSubItem
                          title={t("sidebar.shipping_rates")}
                          to={SHIPPING_RATES_ROOT_PATH}
                          path={SHIPPING_RATES_PREVIEW_PATH}
                          disabled={isNotMaker}
                        />
                      </>
                    )}
                  </SidebarSubList>
                </>
              ) : (
                <SidebarItem disabled={isNotMaker}>
                  <SidebarLink to={COMMERCE_RATES_ROOT_PATH} tooltipTitle={t("sidebar.tooltip")} disabled={isNotMaker}>
                    <SidebarItemContent
                      text={t("sidebar.group.commerce")}
                      icon={<BmShoppingIcon id="market-icon" color={designColors.white} size="xs" />}
                    />
                  </SidebarLink>
                </SidebarItem>
              )}
            </SidebarSection>
          </Can>

          <Can I="read" a="listings">
            <SidebarSection>
              <SidebarItem disabled={isNotMaker}>
                <SidebarLink to={LISTINGS_PATH} tooltipTitle={t("sidebar.tooltip")} disabled={isNotMaker}>
                  <SidebarItemContent
                    text={t("sidebar.listings")}
                    icon={<BmGlobeIcon id="listings-icon" color={designColors.white} size="sm" />}
                  />
                </SidebarLink>
              </SidebarItem>
            </SidebarSection>
          </Can>

          {isAnyWarehouse && (
            <Can I="read" a="fulfillment">
              <SidebarSection>
                <SidebarSubList
                  id="fulfillment"
                  title={t("sidebar.group.fulfillment")}
                  icon={<BmWarehouseIcon id="fulfillment-icon" color={designColors.white} size="xs" />}
                  path={[STOCK_MOVEMENTS_ROOT_PATH, WAREHOUSES_ROOT_PATH, FULFILLMENT_RULES_ROOT_PATH]}
                  tooltipTitle={t("sidebar.tooltip")}
                  disabled={isNotMaker}
                >
                  {!isOnlyExternalWarehouse && (
                    <SidebarSubItem
                      title={t("sidebar.stock_movements")}
                      to={STOCK_MOVEMENTS_ROOT_PATH}
                      path={STOCK_MOVEMENTS_ROOT_PATH}
                      disabled={isNotMaker}
                    />
                  )}
                  <SidebarSubItem
                    title={t("sidebar.warehouses")}
                    to={WAREHOUSES_ROOT_PATH}
                    path={WAREHOUSES_ROOT_PATH}
                    disabled={isNotMaker}
                  />
                  {allWarehousesData!.warehouses.length > 1 && (
                    <SidebarSubItem
                      title={t("sidebar.fulfillment_rules")}
                      to={FULFILLMENT_RULES_ROOT_PATH}
                      path={FULFILLMENT_RULES_ROOT_PATH}
                      disabled={isNotMaker}
                    />
                  )}
                </SidebarSubList>
              </SidebarSection>
            </Can>
          )}

          <Can I="read" a="settings">
            <SidebarSection>
              <SidebarSubList
                id="settings"
                title={t("sidebar.group.settings")}
                icon={<BmSettingsIcon id="settings-icon" color={designColors.white} size="xs" />}
                path={[
                  COMPANY_INFO_ROOT_PATH,
                  USERS_PATH,
                  PAYMENT_INFO_ROOT_PATH,
                  LOCATIONS_ROOT_PATH,
                  CONFIGURATION_ROOT_PATH,
                  BRANDS_PATH,
                ]}
                tooltipTitle={t("sidebar.tooltip")}
                disabled={isNotMaker}
              >
                <SidebarSubItem
                  title={t("sidebar.company_info")}
                  to={COMPANY_INFO_ROOT_PATH}
                  path={COMPANY_INFO_ROOT_PATH}
                  disabled={isNotMaker}
                />
                <SidebarSubItem title={t("sidebar.users")} to={USERS_PATH} path={USERS_PATH} disabled={isNotMaker} />
                <SidebarSubItem
                  title={t("sidebar.payment_info")}
                  to={PAYMENT_INFO_ROOT_PATH}
                  path={PAYMENT_INFO_ROOT_PATH}
                  disabled={isNotMaker}
                />
                {storefront && (
                  <SidebarSubItem
                    title={t("sidebar.webshop_settings")}
                    to={CONFIGURATION_ROOT_PATH}
                    path={CONFIGURATION_ROOT_PATH}
                    disabled={isNotMaker}
                  />
                )}
                {isClickAndCollect && (
                  <SidebarSubItem
                    title={t("sidebar.click_and_collect")}
                    to={LOCATIONS_ROOT_PATH}
                    path={LOCATIONS_ROOT_PATH}
                    disabled={isNotMaker}
                  />
                )}
                {maker.trade && (
                  <SidebarSubItem
                    title={t("sidebar.brands")}
                    to={BRANDS_PATH}
                    path={BRANDS_PATH}
                    disabled={isNotMaker}
                  />
                )}
              </SidebarSubList>
            </SidebarSection>
          </Can>

          <Can I="manage" a="businessTools">
            <SidebarSection>
              <SidebarItem disabled={isNotMaker}>
                <SidebarLink to={BUSINESS_TOOLS_PATH} tooltipTitle={t("sidebar.tooltip")} disabled={isNotMaker}>
                  <SidebarItemContent
                    text={t("sidebar.business_tools")}
                    icon={<BmBookOpenIcon id="book-icon" color={designColors.white} size="sm" />}
                  />
                </SidebarLink>
              </SidebarItem>
            </SidebarSection>
          </Can>

          <SidebarSection>
            <SidebarItem>
              <Link href={WATS_NEW_URL} target="_blank" underline="none" sx={{width: "100%"}}>
                <SidebarItemContent
                  text={t("sidebar.wats_new")}
                  icon={<BmEnergyIcon id="energy-icon" color={designColors.white} size="xs" />}
                />
              </Link>
            </SidebarItem>
          </SidebarSection>
        </Stack>
      </MenuList>
      <MenuList sx={{py: 4}}>
        <SidebarSection>
          <SidebarUser />
        </SidebarSection>
      </MenuList>
    </Box>
  );
};
