import get from "lodash.get";
import React, {useCallback, useMemo, useState} from "react";
import {useController} from "react-hook-form";

import {BmAutocomplete, IAutocompleteOption} from "~/components/inputs/Autocomplete";

import {ControlledInputProps} from "../../_common/types";

interface BmCountrySearchProps {
  id?: string;
  name: string;
  label?: string;
  value?: string | null;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  options: IAutocompleteOption[];
  onSelectOption: (value: string | null) => void;
}

export const BmCountrySearch = React.forwardRef<HTMLDivElement, BmCountrySearchProps>(function CountrySearch(
  {name, label, value, helperText, error, disabled, options, onSelectOption, ...restProps},
  ref,
) {
  const selectedCountry = useMemo(() => options.find(({id}) => id === value), [value, options]);
  const renderValue = useMemo(() => selectedCountry?.label, [selectedCountry?.label]);
  const [localOptions, setLocalOptions] = useState<IAutocompleteOption[]>(options);

  const handleSelectOption = useCallback(
    (value: string | IAutocompleteOption | null) => {
      value = value as IAutocompleteOption | null;
      onSelectOption(value?.id || null);
    },
    [onSelectOption],
  );

  const handleInputChange = useCallback(
    (inputValue: string) => {
      if (inputValue) {
        setLocalOptions(options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase())));
      } else {
        setLocalOptions(options);
      }
    },
    [options],
  );

  return (
    <BmAutocomplete
      {...restProps}
      ref={ref}
      name={name}
      value={selectedCountry}
      renderValue={renderValue}
      label={label}
      options={localOptions}
      onSelectOption={handleSelectOption}
      onInputChange={handleInputChange}
      helperText={get(error, "message", helperText)}
      error={!!error}
      startAdornment={selectedCountry?.image}
      disabled={disabled}
    />
  );
});

export interface BmControlledCountrySearchProps
  extends ControlledInputProps,
    Omit<BmCountrySearchProps, "onSelectOption" | "value" | "error"> {
  onSelectOption?: (value: string | null) => void;
}

export const BmControlledCountrySearch: React.FC<BmControlledCountrySearchProps> = ({
  name,
  control,
  rules,
  onSelectOption,
  helperText,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleSelectOption = useCallback(
    (value) => {
      onSelectOption?.(value);
      field.onChange(value);
    },
    [field, onSelectOption],
  );

  return (
    <BmCountrySearch
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onSelectOption={handleSelectOption}
    />
  );
};
