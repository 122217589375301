import {cx} from "@emotion/css";
import TableBody, {TableBodyProps} from "@mui/material/TableBody";

import {styles} from "./TableBody.styles";
import {useBmTableConfigContext} from "../TableSettings.context";

interface BmTableBodyProps extends TableBodyProps {}

export const BmTableBody: React.FC<BmTableBodyProps> = (props) => {
  const {newMobileDesign} = useBmTableConfigContext();
  return <TableBody {...props} className={cx(styles.root(newMobileDesign), props.className)} />;
};
