import {cx} from "@emotion/css";

import {ReactComponent as SvgCheckboxIndeterminate} from "~/assets/svg/checkbox-indeterminate.svg";
import {designColors} from "~/ui";

import {IIconBase} from "../../_common/types";
import {commonStyles} from "../../_common/styles";
import {simpleIconStyles} from "../styles";

export const BmCheckboxIndeterminateIcon: React.FC<IIconBase> = ({
  id,
  size = "lg",
  color = designColors.primary,
  className,
}) => {
  return (
    <SvgCheckboxIndeterminate
      id={id}
      className={cx(simpleIconStyles(size), commonStyles.fillColor(color), className)}
    />
  );
};
