import {useMemo} from "react";
import MuiChip from "@mui/material/Chip";
import {cx} from "@emotion/css";

import {designColors} from "~/ui";

import {ChipType} from "../IChip";
import {commonStyles} from "../Chip.styles";

export const BmScheduledChip: React.FC<ChipType> = ({sx, className, label, variant = "outlined"}) => {
  const colors = useMemo(
    () => ({
      textColor: designColors.yellow_4,
      bgColor: designColors.yellow_1,
      borderColor: designColors.yellow_3,
    }),
    [],
  );
  return (
    <MuiChip label={label} variant={variant} className={cx(commonStyles.chip(colors, variant), className)} sx={sx} />
  );
};
