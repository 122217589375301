import {css} from "@emotion/css";

import {designColors} from "~/ui";

import {VATValidationInputStateEnum} from "./VATValidationInput";

export const styles = {
  root: (inputState: VATValidationInputStateEnum) =>
    ({
      [VATValidationInputStateEnum.empty]: css``,
      [VATValidationInputStateEnum.filled]: css``,
      [VATValidationInputStateEnum.validating]: css``,
      [VATValidationInputStateEnum.valid]: css`
        &.MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
          border-color: ${designColors.green_2} !important;
        }
      `,
      [VATValidationInputStateEnum.noMatch]: css``,
      [VATValidationInputStateEnum.invalid]: css`
        &.MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
          border-color: ${designColors.error} !important;
        }
      `,
    })[inputState],
};
