import {useState} from "react";

export type UseLocalStorageTuple = [string | null, (value: string | boolean) => void, () => void];

export function useLocalStorage(key: string): UseLocalStorageTuple {
  const [storedValue, setStoredValue] = useState<string | null>(() => {
    return window.localStorage.getItem(key);
  });

  const setValue = (value: any) => {
    setStoredValue(value);
    window.localStorage.setItem(key, value);
  };

  const removeValue = () => {
    setStoredValue(null);
    window.localStorage.removeItem(key);
  };

  return [storedValue, setValue, removeValue];
}
