import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  autocomplete: css`
    &.MuiAutocomplete-root .MuiInputBase-root {
      padding: 11px 12px;

      .MuiInputBase-input {
        padding: 0;
      }
      .MuiAutocomplete-endAdornment {
        top: calc(50% - 12px);
      }
    }
    .MuiAutocomplete-popupIndicator {
      background: none;
      margin-right: 5px !important;
      transform: none !important;
    }
  `,
  list: css`
    &.MuiList-root {
      padding: 0;
      background-color: ${designColors.white};
      border-radius: 2px;
      box-shadow:
        0px 0px 2px 0px rgba(0, 0, 0, 0.2),
        0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }
    .MuiListSubheader-root {
      text-transform: uppercase;
      color: ${designColors.grey_4};
      font-size: ${designTokens.fontSize.xs};
      font-weight: ${designTokens.fontWeight.semiBold};
      letter-spacing: 1px;
      margin: 0;
    }
    .MuiAutocomplete-noOptions,
    .MuiAutocomplete-loading {
      color: ${designColors.grey_5};
      font-size: ${designTokens.fontSize.sm};
    }
    .MuiAutocomplete-listbox {
      padding: 0;
      > li {
        &:first-child .MuiAutocomplete-groupLabel {
          border-top: none;
        }

        .MuiAutocomplete-groupLabel {
          padding: 15px 12px 9px;
          background-color: ${designColors.grey_1};
          color: ${designColors.grey_5};
          font-size: ${designTokens.fontSize.xsm};
          line-height: 16px;
          font-weight: ${designTokens.fontWeight.bold};
          border-top: 1px solid ${designColors.grey_2};
        }
      }
    }
  `,
  option: css`
    &.MuiMenuItem-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 15px;
      border-bottom: 1px solid ${designColors.grey_2};
      transition: ${designUtils.transition(0.3)};

      &:last-child {
        border: none;
      }
    }
  `,
  checkboxOption: css`
    &.MuiMenuItem-root {
      display: flex;
      padding: 12px 15px;
      transition: ${designUtils.transition(0.3)};
    }

    .MuiCheckbox-root {
      margin-top: -18px;
    }
  `,
  optionLabel: (disabled?: boolean) => css`
    font-size: ${designTokens.fontSize.sm};
    font-weight: ${designTokens.fontWeight.medium};
    white-space: normal;
    ${disabled &&
    css`
      opacity: 0.4;
    `}
  `,
  optionImage: css`
    display: inline-flex;
    margin-top: 1px;
  `,
  highlight: css`
    color: ${designColors.primary};
    font-weight: ${designTokens.fontWeight.bold};
  `,
  details: css`
    font-size: ${designTokens.fontSize.xsm};
    color: ${designColors.grey_5};
    white-space: normal;
  `,
};
