import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";
import {theme} from "~/ui/theme";

export const styles = {
  tableHead: css`
    &.MuiTableRow-head .MuiTableCell-root {
      background-color: ${designColors.grey_6};
      font-weight: ${designTokens.fontWeight.semiBold};

      ${theme.breakpoints.down("md")} {
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      span {
        word-break: normal;
      }
    }
  `,
};
