import {MenuItem, Divider, TextField, TextFieldProps} from "@mui/material";
import {useController} from "react-hook-form";
import React, {ChangeEventHandler, useCallback} from "react";
import {cx} from "@emotion/css";
import get from "lodash.get";

import {IBaseSelectProps} from "~/components/inputs";
import {BmChevronIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";
import {useOpenClose} from "~/hooks";

import {commonStyles} from "../../_common/styles";
import {CommonInputType, ControlledInputProps} from "../../_common/types";
import {BmInputHelperText} from "../../_common/InputHelperText";
import {styles} from "./DynamicSelect.styles";

export interface IDynamicSelectOption {
  label: React.ReactNode;
  value: string | number;
}

export interface BmDynamicSelectProps extends IBaseSelectProps, CommonInputType {
  options: IDynamicSelectOption[];
  customOption?: IDynamicSelectOption;
  focusOnMount?: boolean;
  displayEmpty?: boolean;
  noOptionsText?: string;
  maxHeight?: number;

  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const BmDynamicSelect = React.forwardRef<HTMLInputElement, BmDynamicSelectProps & TextFieldProps>(
  function BmDynamicSelect(
    {
      options,
      customOption,
      className,
      focusOnMount,
      displayEmpty = false,
      noOptionsText,
      value,
      error,
      onChange,
      helperText,
      maxHeight,
      ...restProps
    },
    ref,
  ) {
    const [isFocused, focus, unfocus] = useOpenClose(false);
    return (
      <TextField
        {...restProps}
        select
        onChange={onChange}
        value={value}
        error={error}
        variant="outlined"
        fullWidth={true}
        className={cx(commonStyles.input(), styles.root(!!error), className)}
        InputProps={{notched: isFocused || !!value}}
        InputLabelProps={{shrink: isFocused || !!value}}
        SelectProps={{
          MenuProps: {PaperProps: {sx: {maxHeight}}},
          displayEmpty,
          IconComponent: (props) => (
            <BmChevronIcon {...props} color={error ? designColors.error : designColors.black} />
          ),
          onOpen: focus,
          onClose: unfocus,
        }}
        inputProps={{ref}}
        autoFocus={focusOnMount}
        helperText={helperText && <BmInputHelperText helperText={helperText} />}
      >
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            {noOptionsText}
          </MenuItem>
        )}
        {customOption && <Divider sx={{m: "3px 12px", borderColor: designColors.grey_2}} />}
        {customOption && <MenuItem value={customOption.value}>{customOption.label}</MenuItem>}
      </TextField>
    );
  },
);

export interface BmControlledDynamicSelectProps
  extends ControlledInputProps,
    Omit<BmDynamicSelectProps, "onChange" | "value" | "error"> {
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export const BmControlledDynamicSelect: React.FC<BmControlledDynamicSelectProps> = ({
  name,
  helperText,
  control,
  rules,
  defaultValue,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmDynamicSelect
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleChange}
    />
  );
};
