import {enhanceImageUrl} from "~/utils/images";

interface EnhancedCompanyFields {
  logoUrl?: string | null;
  printLogoUrl?: string | null;
  faviconUrl?: string | null;
}

export const enhanceCompanyData = <T extends EnhancedCompanyFields>(companyData: T): T => {
  return {
    ...companyData,
    logoUrl: enhanceImageUrl(companyData.logoUrl),
    printLogoUrl: enhanceImageUrl(companyData.printLogoUrl),
    faviconUrl: enhanceImageUrl(companyData.faviconUrl, {noTransformations: true}),
  };
};
