import {rgba} from "polished";
import {MouseEventHandler, useCallback, useState} from "react";
import {useController} from "react-hook-form";
import get from "lodash.get";
import {cx} from "@emotion/css";
import {IconButton} from "@mui/material";

import {BmCloseIcon, BmSearchIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";
import {useDidUpdate} from "~/hooks";

import {BmControlledTextInputProps, BmTextInput, BmTextInputProps} from "../../TextInput";

let timeoutId: ReturnType<typeof setTimeout>;

export interface BmSearchInputProps extends Omit<BmTextInputProps, "onChange" | "endAdornment"> {
  onChange: (value: string) => void;
  onClearBtnClick: MouseEventHandler<HTMLButtonElement>;
}

export const BmSearchInput: React.FC<BmSearchInputProps> = ({
  className,
  value,
  onChange,
  onClearBtnClick,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value);

  useDidUpdate(() => {
    if (localValue === value) return;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => onChange(localValue!), 600);
  }, [localValue]);

  useDidUpdate(() => {
    setLocalValue(value);
  }, [value, setLocalValue]);

  return (
    <BmTextInput
      {...props}
      className={cx(className)}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      endAdornment={
        !value?.length ? (
          <BmSearchIcon id={`${props.name}-search-icon`} size="xs" color={rgba(designColors.black, 0.4)} />
        ) : (
          <IconButton onClick={onClearBtnClick} edge="end">
            <BmCloseIcon id={`${props.name}-clear-search-icon`} color={designColors.black} />
          </IconButton>
        )
      }
    />
  );
};

export interface BmControlledSearchInputProps
  extends BmControlledTextInputProps,
    Omit<BmSearchInputProps, "onChange" | "value" | "error"> {}

export const BmControlledSearchInput: React.FC<BmControlledSearchInputProps> = ({
  name,
  control,
  rules,
  helperText,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: string) => {
      field.onChange(value);
    },
    [field],
  );

  return (
    <BmSearchInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
