export const stagingBrandColor = "#323048";
export const productionBrandColor = "#000045";

const blue_4 = ((localStorage?: Storage) => {
  const storedBrandColor = localStorage?.getItem("brandColor");
  if (storedBrandColor) return storedBrandColor;
  return ["local", "staging"].includes(process.env.BEMAKERS_ENV!) ? stagingBrandColor : productionBrandColor;
})(window.localStorage);

export const designColors = {
  black: "#000000",
  colorInk: "#141414",
  white: "#FFFFFF",
  off_white: "#FCFCFC",

  grey_1: "#F5F5F5",
  grey_2: "#DBDBDB",
  grey_3: "#C2C2C2",
  grey_4: "#A8A8A8",
  grey_5: "#808080",
  grey_6: "#F7F7F7",

  blue_1: "#E3F2FC",
  blue_2: "#5174CF",
  blue_3: "#00499C",
  blue_4,

  brown_1: "#F5EEE2",

  green_1: "#E8F5EA",
  green_2: "#4E8B51",
  green_3: "#1E5D28",

  red_1: "#FFEBEE",
  red_2: "#F05545",
  red_3: "#B72322",
  red_4: "#7F0807",
  pink: "#F8B4AD",

  yellow_1: "#FFF6C8",
  yellow_3: "#897F40",
  yellow_4: "#332F13",

  primaryStorefront: "#F9B971",

  seoLink: "#0D18A5",
  seoDescription: "#315368",

  get primary() {
    return this.blue_2;
  },
  get brand() {
    return this.blue_4;
  },
  get error() {
    return this.red_3;
  },
};
