import React from "react";

import {CompanySummaryFragment} from "~/api/generated";

import {CompanyInfoContextProvider} from "./CompanyInfo.context";
import {useCompanyInfoQuery} from "./useCompanyInfoQuery";

export const withCompanyInfo =
  <P,>(Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    const {data: companyInfoData, isLoading: isCompanyInfoDataLoading} = useCompanyInfoQuery();

    if (!companyInfoData && isCompanyInfoDataLoading) return null;

    return (
      <CompanyInfoContextProvider value={companyInfoData as CompanySummaryFragment}>
        <Component {...props} />
      </CompanyInfoContextProvider>
    );
  };
