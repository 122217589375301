import {css} from "@emotion/css";

export const styles = {
  primaryButton: css`
    &.MuiButton-root {
      &,
      &:hover {
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 69, 0.25);
      }
    }
    &.MuiLoadingButton-loading {
      min-width: 100px;
    }
  `,
};
