import {css} from "@emotion/css";

import {designTokens} from "~/ui";

export const styles = {
  select: css`
    .MuiList-root {
      li {
        &:not(.Mui-disabled) {
          font-size: ${designTokens.fontSize.sm};
          font-weight: ${designTokens.fontWeight.medium};
          padding: 12px 14px;
          padding-right: 30px;
        }
      }
    }
  `,
  placeholder: css`
    height: 0;
    min-height: 0 !important;
    padding: 0 !important;

    opacity: 0;
  `,
  label: css`
    &.MuiInputLabel-root {
      max-width: calc(100% - 34px);
      &:not(.MuiInputLabel-shrink) {
        font-weight: ${designTokens.fontWeight.semiBold};
        color: rgba(0, 0, 0, 0.87);
        transform: translate(12px, 11px) scale(1);
      }
    }
  `,
};
