import MuiChip from "@mui/material/Chip";
import {cx} from "@emotion/css";
import {useMemo} from "react";
import CloseIcon from "@mui/icons-material/Close";

import {designColors} from "~/ui";

import {ChipType} from "../IChip";
import {commonStyles} from "../Chip.styles";

export const BmInfoChip: React.FC<ChipType> = ({sx, className, label, variant = "outlined", onDelete}) => {
  const colors = useMemo(
    () => ({
      textColor: designColors.blue_3,
      bgColor: designColors.blue_1,
      borderColor: designColors.blue_3,
    }),
    [],
  );
  return (
    <MuiChip
      label={label}
      variant={variant}
      className={cx(commonStyles.chip(colors, variant), className)}
      sx={sx}
      deleteIcon={<CloseIcon />}
      onDelete={onDelete}
    />
  );
};
