import {css} from "@emotion/css";

export const styles = {
  uploadInputLabel: css`
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  `,
  input: css`
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  `,
};
