import {css} from "@emotion/css";

import {designConstants, designTokens, designUtils} from "~/ui";

export const commonStyles = {
  button: css`
    &.MuiButton-root {
      ${designUtils.baseFontStyles};

      border-radius: ${designConstants.borderRadius};

      font-weight: ${designTokens.fontWeight.semiBold};
      text-transform: none;

      &.MuiButton-size {
        &Large {
          min-height: 42px;
          padding: 0 16px;
        }
        &Medium {
          min-height: 36px;
          padding: 0 12px;
        }
        &Small {
          min-height: 30px;
          padding: 0 12px;
        }
      }
    }
    &.MuiLoadingButton-loading {
      min-width: 100px;
      color: transparent !important;
    }
  `,
};
